var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"labor-recruitment-id"}},[_c('div',{staticClass:"scroll-table table-model"},[_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'worker')?_c('span',[_c('b-form-input',{attrs:{"type":"number"},on:{"change":function($event){return _vm.handleCountWorker($event, props.row.collectJobId, props.row.femaleWorker)}},model:{value:(props.row.worker),callback:function ($$v) {_vm.$set(props.row, "worker", $$v)},expression:"props.row.worker"}})],1):(props.column.field === 'femaleWorker')?_c('span',[_c('b-form-input',{attrs:{"type":"number"},on:{"change":function($event){return _vm.handleCountFemaleWorker($event, props.row.collectJobId, props.row.worker)}},model:{value:(props.row.femaleWorker),callback:function ($$v) {_vm.$set(props.row, "femaleWorker", $$v)},expression:"props.row.femaleWorker"}})],1):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }